import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // If you're using React Router

const ThankYouPage = () => {
  const history = useNavigate ();

  useEffect(() => {
    // Redirect after 5 seconds or show a message
    setTimeout(() => {
      history("/"); // Redirect to homepage or dashboard
    }, 5000);
  }, [history]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="text-center p-8 bg-white shadow-lg rounded-md">
        <h2 className="text-2xl font-semibold mb-4">Thank You for Applying!</h2>
        <p className="mb-4">A representative will contact you within 3 days. Thank you for your interest in working with Ree!</p>
        <p className="text-sm text-gray-500">You will be redirected shortly.</p>
      </div>
    </div>
  );
};

export default ThankYouPage;
