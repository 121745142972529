import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography } from '@mui/material';
import React from 'react';


const EmailConfirmed = () => {

  return (
    <Box
    sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: 'background.default',
      p: 2,
      textAlign: 'center',
    }}
  >

    {/* Confirmation Icon */}
    <CheckCircleOutlineIcon
      sx={{
        fontSize: 80,
        color: '#FF9933', // Matches your primary app color
        mb: 2,
      }}
    />

    {/* Title */}
    <Typography
      variant="h4"
      sx={{
        mb: 2,
        fontWeight: '600',
        color: 'text.primary',
      }}
    >
      Email Confirmed!
    </Typography>

    {/* Message */}
    <Typography
      variant="body1"
      sx={{
        mb: 4,
        color: 'text.secondary',
        maxWidth: 400,
      }}
    >
      Your email has been successfully verified. You can now log in to your account and enjoy our services.
    </Typography>

    {/* Log In Button */}
  </Box>
  );
};

export default EmailConfirmed;
