import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef, useState } from "react";
import {
    FaArrowLeft,
    FaChevronDown,
    FaClock,
    FaFilter,
    FaPlay,
    FaSearch,
    FaTimes
} from "react-icons/fa";
import { Link } from "react-router-dom";

// Video Modal Component (same as in HomePage)
const VideoModal = ({ video, onClose }) => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    // Focus trap
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);
  
  useEffect(() => {
    // Auto-play when modal opens
    if (videoRef.current) {
      videoRef.current.play().catch(err => {
        console.log('Autoplay prevented:', err);
      });
    }
  }, []);
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
      <div className="relative bg-white rounded-lg w-full max-w-4xl overflow-hidden shadow-xl" onClick={(e) => e.stopPropagation()}>
        <div className="absolute top-4 right-4 z-10">
          <button
            onClick={onClose}
            className="bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-all"
            aria-label="Close modal"
          >
            <FaTimes size={20} />
          </button>
        </div>
        
        <div className="p-2">
          <div className="aspect-w-16 aspect-h-9 w-full overflow-hidden">
            <video
              ref={videoRef}
              className="w-full h-full object-contain"
              controls
              preload="metadata"
              src={video.videoUrl}
            >
              Your browser does not support the video tag.
            </video>
          </div>
          
          <div className="p-6">
            <h3 className="text-2xl font-bold mb-2">{video.title}</h3>
            <p className="text-gray-700">{video.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Expanded tutorials data
const allTutorials = [
  // Original tutorials
  {
    id: 1,
    title: "Getting Started with Ree",
    description: "Learn how to set up your Ree account and navigate the dashboard.",
    thumbnail: "/api/placeholder/400/225",
    duration: "3:45",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Getting Started",
    difficulty: "Beginner",
    views: 5487,
    date: "2025-01-15"
  },
  {
    id: 2,
    title: "Store Management Tutorial",
    description: "Master inventory tracking, sales processing, and reporting features.",
    thumbnail: "/api/placeholder/400/225",
    duration: "5:20",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Store Management",
    difficulty: "Intermediate",
    views: 3256,
    date: "2025-01-22"
  },
  {
    id: 3,
    title: "Hiring Professionals",
    description: "How to find, vet, and hire skilled workers through the Ree platform.",
    thumbnail: "/api/placeholder/400/225",
    duration: "4:15",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Worker Hiring",
    difficulty: "Beginner",
    views: 4123,
    date: "2025-01-28"
  },
  {
    id: 4,
    title: "Analytics Dashboard Walkthrough",
    description: "Get insights from your business data with Ree's powerful analytics tools.",
    thumbnail: "/api/placeholder/400/225",
    duration: "6:10",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Analytics",
    difficulty: "Intermediate",
    views: 2789,
    date: "2025-02-05"
  },
  // Additional tutorials
  {
    id: 5,
    title: "Advanced Inventory Management",
    description: "Learn advanced techniques for managing complex inventories across multiple locations.",
    thumbnail: "/api/placeholder/400/225",
    duration: "8:22",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Store Management",
    difficulty: "Advanced",
    views: 1876,
    date: "2025-02-12"
  },
  {
    id: 6,
    title: "Processing Sales and Returns",
    description: "A comprehensive guide to handling sales, refunds, and returns efficiently.",
    thumbnail: "/api/placeholder/400/225",
    duration: "7:15",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Store Management",
    difficulty: "Intermediate",
    views: 2156,
    date: "2025-02-18"
  },
  {
    id: 7,
    title: "Setting Up User Permissions",
    description: "Learn to set proper access controls for your team members based on their roles.",
    thumbnail: "/api/placeholder/400/225",
    duration: "4:50",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Getting Started",
    difficulty: "Intermediate",
    views: 1982,
    date: "2025-02-25"
  },
  {
    id: 8,
    title: "Mobile App Features Overview",
    description: "Discover all the features available in the Ree mobile app for on-the-go management.",
    thumbnail: "/api/placeholder/400/225",
    duration: "6:35",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Getting Started",
    difficulty: "Beginner",
    views: 3487,
    date: "2025-03-01"
  },
  {
    id: 9,
    title: "Creating Professional Reports",
    description: "Generate custom reports to gain valuable insights into your business performance.",
    thumbnail: "/api/placeholder/400/225",
    duration: "9:12",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Analytics",
    difficulty: "Advanced",
    views: 1543,
    date: "2025-03-05"
  },
  {
    id: 10,
    title: "Worker Rating and Review System",
    description: "How to use the rating system to find the best professionals for your needs.",
    thumbnail: "/api/placeholder/400/225",
    duration: "5:45",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Worker Hiring",
    difficulty: "Beginner",
    views: 2765,
    date: "2025-03-10"
  },
  {
    id: 11,
    title: "Customer Relationship Management",
    description: "Build stronger relationships with your customers using Ree's CRM features.",
    thumbnail: "/api/placeholder/400/225",
    duration: "7:30",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Customer Service",
    difficulty: "Intermediate",
    views: 1856,
    date: "2025-03-15"
  },
  {
    id: 12,
    title: "Integrating Payment Systems",
    description: "Set up and manage multiple payment options for your customers.",
    thumbnail: "/api/placeholder/400/225",
    duration: "8:15",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4",
    category: "Store Management",
    difficulty: "Advanced",
    views: 2103,
    date: "2025-03-18"
  }
];

// Filter categories
const categories = ["All Categories", "Getting Started", "Store Management", "Worker Hiring", "Analytics", "Customer Service"];
const difficulties = ["All Difficulties", "Beginner", "Intermediate", "Advanced"];
const sortOptions = ["Newest", "Most Viewed", "Longest", "Shortest"];

const AllTutorialsPage = () => {
  const [activeVideoModal, setActiveVideoModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [selectedDifficulty, setSelectedDifficulty] = useState("All Difficulties");
  const [sortBy, setSortBy] = useState("Newest");
  const [showFilters, setShowFilters] = useState(false);
  
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      easing: 'ease-out-cubic',
    });
  }, []);
  
  // Filter and sort tutorials
  const filteredTutorials = allTutorials.filter(tutorial => {
    const matchesSearch = tutorial.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
                        tutorial.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === "All Categories" || tutorial.category === selectedCategory;
    const matchesDifficulty = selectedDifficulty === "All Difficulties" || tutorial.difficulty === selectedDifficulty;
    
    return matchesSearch && matchesCategory && matchesDifficulty;
  }).sort((a, b) => {
    switch(sortBy) {
      case "Newest":
        return new Date(b.date) - new Date(a.date);
      case "Most Viewed":
        return b.views - a.views;
      case "Longest":
        return convertDurationToSeconds(b.duration) - convertDurationToSeconds(a.duration);
      case "Shortest":
        return convertDurationToSeconds(a.duration) - convertDurationToSeconds(b.duration);
      default:
        return new Date(b.date) - new Date(a.date);
    }
  });
  
  // Helper function to convert duration string to seconds
  const convertDurationToSeconds = (duration) => {
    const [minutes, seconds] = duration.split(':').map(Number);
    return minutes * 60 + seconds;
  };
  
  const clearFilters = () => {
    setSearchTerm("");
    setSelectedCategory("All Categories");
    setSelectedDifficulty("All Difficulties");
    setSortBy("Newest");
  };
  
  const openVideoModal = (videoId) => {
    setActiveVideoModal(allTutorials.find(video => video.id === videoId));
  };
  
  const closeModal = () => {
    setActiveVideoModal(null);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-primary text-white py-10 px-6 lg:px-16">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row md:items-center mb-8">
            <Link to="/" className="inline-flex items-center text-white hover:text-gray-200 transition mb-4 md:mb-0">
              <FaArrowLeft className="mr-2" />
              Back to Home
            </Link>
            <h1 className="text-3xl md:text-4xl font-bold md:ml-auto">Video Tutorials Library</h1>
          </div>
          
          <p className="text-lg mb-8 max-w-2xl">
            Browse our comprehensive collection of video tutorials to help you get the most out of Ree's features and functionalities.
          </p>
          
          {/* Search Bar */}
          <div className="relative w-full max-w-2xl">
            <input
              type="text"
              placeholder="Search tutorials..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-12 pr-4 py-3 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500">
              <FaSearch />
            </div>
            
            <button 
              className="md:hidden absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
              onClick={() => setShowFilters(!showFilters)}
            >
              <FaFilter size={18} />
            </button>
          </div>
        </div>
      </header>
      
      {/* Filters Section */}
      <div className="bg-white border-b border-gray-200 py-4 px-6 lg:px-16 hidden md:block">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center gap-4">
            <div className="relative">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="appearance-none bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {categories.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                <FaChevronDown size={12} />
              </div>
            </div>
            
            <div className="relative">
              <select
                value={selectedDifficulty}
                onChange={(e) => setSelectedDifficulty(e.target.value)}
                className="appearance-none bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {difficulties.map((difficulty) => (
                  <option key={difficulty} value={difficulty}>{difficulty}</option>
                ))}
              </select>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                <FaChevronDown size={12} />
              </div>
            </div>
            
            <div className="relative ml-auto">
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="appearance-none bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {sortOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                <FaChevronDown size={12} />
              </div>
            </div>
            
            {(searchTerm || selectedCategory !== "All Categories" || selectedDifficulty !== "All Difficulties") && (
              <button
                onClick={clearFilters}
                className="text-sm text-primary hover:text-primary-dark flex items-center gap-1"
              >
                <FaTimes size={12} />
                Clear Filters
              </button>
            )}
          </div>
        </div>
      </div>
      
      {/* Mobile Filters (Collapsible) */}
      {showFilters && (
        <div className="bg-white border-b border-gray-200 py-4 px-6 md:hidden">
          <div className="flex flex-col gap-3">
            <div className="relative">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="appearance-none w-full bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {categories.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                <FaChevronDown size={12} />
              </div>
            </div>
            
            <div className="relative">
              <select
                value={selectedDifficulty}
                onChange={(e) => setSelectedDifficulty(e.target.value)}
                className="appearance-none w-full bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {difficulties.map((difficulty) => (
                  <option key={difficulty} value={difficulty}>{difficulty}</option>
                ))}
              </select>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                <FaChevronDown size={12} />
              </div>
            </div>
            
            <div className="relative">
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="appearance-none w-full bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {sortOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                <FaChevronDown size={12} />
              </div>
            </div>
            
            {(searchTerm || selectedCategory !== "All Categories" || selectedDifficulty !== "All Difficulties") && (
              <button
                onClick={clearFilters}
                className="text-sm text-primary hover:text-primary-dark flex items-center justify-center gap-1 mt-2 py-2"
              >
                <FaTimes size={12} />
                Clear All Filters
              </button>
            )}
          </div>
        </div>
      )}
      
      {/* Main Content */}
      <main className="py-10 px-6 lg:px-16">
        <div className="container mx-auto">
          {/* Results Count */}
          <div className="mb-8 flex justify-between items-center">
            <h2 className="text-gray-700">
              Showing <span className="font-bold">{filteredTutorials.length}</span> of <span className="font-bold">{allTutorials.length}</span> tutorials
            </h2>
            
            {/* Help Text */}
            <p className="text-sm text-gray-500 hidden md:block">
              Click on any tutorial to watch
            </p>
          </div>
          
          {/* No Results */}
          {filteredTutorials.length === 0 && (
            <div className="text-center py-16">
              <div className="text-6xl mb-4">🔍</div>
              <h3 className="text-2xl font-bold text-gray-700 mb-2">No tutorials found</h3>
              <p className="text-gray-600 mb-6">Try adjusting your search or filters to find what you're looking for.</p>
              <button
                onClick={clearFilters}
                className="bg-primary text-white font-medium py-2 px-6 rounded-lg hover:bg-primary-dark transition"
              >
                Clear All Filters
              </button>
            </div>
          )}
          
          {/* Tutorials Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {filteredTutorials.map((tutorial, index) => (
              <div
                key={tutorial.id}
                className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
                data-aos="fade-up"
                data-aos-delay={(index % 4) * 100}
              >
                <div className="relative group cursor-pointer" onClick={() => openVideoModal(tutorial.id)}>
                  <img
                    src={tutorial.thumbnail}
                    alt={tutorial.title}
                    className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <div className="w-16 h-16 bg-primary rounded-full flex items-center justify-center">
                      <FaPlay className="text-white ml-1" size={20} />
                    </div>
                  </div>
                  
                  {/* Duration badge */}
                  <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded flex items-center gap-1">
                    <FaClock size={10} />
                    {tutorial.duration}
                  </div>
                  
                  {/* Difficulty badge */}
                  <div 
                    className={`absolute top-2 left-2 text-xs px-2 py-1 rounded font-medium ${
                      tutorial.difficulty === "Beginner" ? "bg-green-100 text-green-800" :
                      tutorial.difficulty === "Intermediate" ? "bg-blue-100 text-blue-800" :
                      "bg-purple-100 text-purple-800"
                    }`}
                  >
                    {tutorial.difficulty}
                  </div>
                </div>
                
                <div className="p-4">
                  <div className="text-xs text-gray-500 mb-2">{tutorial.category}</div>
                  <h3 className="font-bold text-lg mb-2">{tutorial.title}</h3>
                  <p className="text-gray-600 text-sm line-clamp-2 mb-3">{tutorial.description}</p>
                  
                  <div className="flex justify-between items-center text-xs text-gray-500">
                    <span>{new Date(tutorial.date).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })}</span>
                    <span>{tutorial.views.toLocaleString()} views</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Pagination placeholder */}
          {filteredTutorials.length > 0 && (
            <div className="mt-12 flex justify-center">
              <div className="inline-flex rounded-md shadow-sm">
                <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50">
                  Previous
                </button>
                <button className="px-4 py-2 text-sm font-medium text-white bg-primary border border-primary">
                  1
                </button>
                <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50">
                  2
                </button>
                <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50">
                  3
                </button>
                <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50">
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </main>
      
      {/* Video Modal */}
      {activeVideoModal && (
        <VideoModal
          video={activeVideoModal}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default AllTutorialsPage;