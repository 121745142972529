import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import LoadingSpinner from './LoadingSpinner';

const PrivateRoute = ({ children, allowedRoles }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);

  useEffect(() => {
    const checkAuthAndExistence = async () => {
      try {
        // Get the current session
        const { data: { session } } = await supabase.auth.getSession();
        
        // If no session, user is not authenticated
        if (!session) {
          setIsAuthenticated(false);
          setHasAccess(false);
          setAuthCheckComplete(true);
          return;
        }
        
        setIsAuthenticated(true); // User is authenticated
        
        // Check if the user exists in the 'affiliates' table
        const { data, error } = await supabase
          .from("affiliates")
          .select("id") // Only fetch ID to check existence
          .eq("id", session.user.id)
          .single();
        
        if (error || !data) {
          console.error("Error fetching user:", error || "User not found");
          setHasAccess(false);
        } else {
          setHasAccess(true); // User exists in 'affiliates'
        }
      } catch (err) {
        console.error("Error during authentication check:", err);
        setHasAccess(false);
      } finally {
        setAuthCheckComplete(true); // Mark authentication check as complete
      }
    };
    
    checkAuthAndExistence();
    
    // Subscribe to auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        setIsAuthenticated(false);
        setHasAccess(false);
      }
    });
    
    // Cleanup subscription on unmount
    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Set a timer to ensure the spinner shows for at least 5 seconds
    const timer = setTimeout(() => {
      if (authCheckComplete) {
        setLoading(false);
      }
    }, 2000);
    
    // If auth check completes, update loading state after the timer
    if (authCheckComplete) {
      const delayedLoadingUpdate = setTimeout(() => {
        setLoading(false);
      }, 2000);
      
      return () => clearTimeout(delayedLoadingUpdate);
    }
    
    return () => clearTimeout(timer);
  }, [authCheckComplete]);
  
  if (loading) {
    return <LoadingSpinner />; // Show spinner while loading
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }
  
  if (!hasAccess) {
    return <Navigate to="/" replace />; // Redirect if user doesn't have access
  }
  
  return children; // Render the protected children components
};

export default PrivateRoute;