import { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Save any existing Tawk_API reference
    const existingTawkApi = window.Tawk_API;
    
    // Initialize Tawk_API if it doesn't exist
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    
    // Load the Tawk.to script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/67cf60aa2db662190e789ca6/1im1136p3';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    
    // Append the script to the document
    document.head.appendChild(script);
    
    // Clean up function
    return () => {
      // Clean up only if we created the script in this component
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
      
      // Restore previous Tawk_API if it existed
      if (existingTawkApi) {
        window.Tawk_API = existingTawkApi;
      }
    };
  }, []);
  
  // This component doesn't render anything visible
  return null;
};

export default TawkToChat;