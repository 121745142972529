import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AccountDeletionRequestForm from "./AccountDeletionRequestForm";
import AffiliateDashboard from "./components/AffiliateDashboard";
import AllTutorialsPage from "./components/AllTutorialsPage";
import EmailConfirmed from "./components/EmailConfirmed";
import HomePage from "./components/HomePage";
import PasswordResetPage from "./components/PasswordResetPage";
import PrivacyScreen from "./components/PrivacyScreen";
import PrivateRoute from "./components/PrivateRoute";
import SignUpAffiliate from "./components/signUpAffiliate";
import TawkToChat from "./components/TawkToChat";
import TermsScreen from "./components/TermsScreen";
import ThankYouPage from "./components/ThankYouPage";
import WorkerApplicationForm from "./components/WorkerApplicationForm";


const App = () => {
  return (
    <Router>
       <TawkToChat />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />
        <Route path="/privacy-policy" element={<PrivacyScreen />} />
        <Route path="/terms-condition" element={<TermsScreen />} />
        <Route path="/signup" element={<SignUpAffiliate />} />
        <Route path="/dashboard" element={
          <PrivateRoute >
          <AffiliateDashboard />
          </PrivateRoute>
          } />
        <Route path="/worker-apply" element={<WorkerApplicationForm />} />
        <Route path="/success" element={<ThankYouPage />} />
        <Route path="/delete-account" element={<AccountDeletionRequestForm />} />
        <Route path="/all-tutorials" element={<AllTutorialsPage />} />
        
      </Routes>
    </Router>
  );
};

export default App;
