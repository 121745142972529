import React, { useState } from 'react';

const AccountDeletionRequestForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    reason: '',
    confirmation: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Here you can call your backend API or perform the deletion request logic
      // For now, we'll simulate the submission
      console.log('Account Deletion Request:', formData);

      // Simulating a successful request (replace with actual API call)
      setTimeout(() => {
        setIsSuccess(true);
        setIsLoading(false);
      }, 2000); // Simulate a 2-second delay for the request
    } catch (err) {
      setError('There was an error processing your request.');
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4 text-center">Request Account Deletion</h2>
      {isSuccess ? (
        <div className="text-center text-green-500 mb-4">Your account deletion request has been submitted successfully.</div>
      ) : (
        <form onSubmit={handleSubmit}>
          {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
          <div className="space-y-4">
            {/* Email Field */}
            <div>
              <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="Your Email"
                required
              />
            </div>

            {/* Reason Field */}
            <div>
              <label htmlFor="reason" className="block text-sm font-semibold text-gray-700">
                Reason for Deletion
              </label>
              <textarea
                id="reason"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="Why do you want to delete your account?"
                rows="4"
                required
              ></textarea>
            </div>

            {/* Confirmation Checkbox */}
            <div>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="confirmation"
                  checked={formData.confirmation}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-primary"
                  required
                />
                <span className="ml-2 text-sm text-gray-700">
                  I confirm that I want to delete my account and understand the consequences.
                </span>
              </label>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className={`w-full py-3 bg-primary text-white font-semibold rounded-md shadow-lg hover:bg-orange-600 transition duration-300 ${
                  isLoading ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit Request'}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AccountDeletionRequestForm;
