import { Alert, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import supabase from '../supabaseClient';


const PasswordResetPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();  // Use useNavigate instead of useHistory
  const location = useLocation();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (newPassword.length < 6) {
      setError('Password should be at least 6 characters long');
      return;
    }

    setLoading(true);

    try {
      // Call Supabase to update the password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        setError(updateError.message);
        setLoading(false);
      } else {
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      setError('An error occurred while resetting the password');
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        maxWidth: 500,
        mx: 'auto',
        backgroundColor: '#FFF',
        borderRadius: 2,
        boxShadow: 2,
        textAlign: 'center',
        mt: 6,
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 'bold', color: '#FF9933', mb: 3 }}
      >
        Reset Your Password
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Password reset successfully!
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <TextField
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{
            mb: 2,
            '& .MuiInputBase-root': {
              borderRadius: '12px',
              backgroundColor: '#f5f5f5',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF9933',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF9933',
            },
          }}
          required
        />

        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            mb: 2,
            '& .MuiInputBase-root': {
              borderRadius: '12px',
              backgroundColor: '#f5f5f5',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF9933',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF9933',
            },
          }}
          required
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{
            py: 1.5,
            mt: 2,
            fontWeight: 'bold',
            backgroundColor: '#FF9933',
            '&:hover': {
              backgroundColor: '#ff7a00', // Darker shade on hover
            },
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Reset Password'
          )}
        </Button>
      </form>

      {/* Optional Logo or Illustration */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2" sx={{ color: '#555' }}>
          Need help?{' '}
          <Button
            sx={{ textTransform: 'none', color: '#FF9933', fontWeight: 'bold' }}
            onClick={() => navigate('/#contacts')}
          >
            Contact Support
          </Button>
        </Typography>
      </Box>
    </Box>
  );
};

export default PasswordResetPage;
