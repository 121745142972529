import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import schoolLogo from '../Assests/logo1.jpg';

const LoadingSpinner = ({ onComplete }) => {
  const [visible, setVisible] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onComplete) onComplete();
    }, 5000);

    // Progress animation
    const progressInterval = setInterval(() => {
      setProgress(prev => {
        const next = prev + 1;
        return next > 100 ? 100 : next;
      });
    }, 50);

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [onComplete]);

  if (!visible) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(135deg, #0f172a 0%, #1e293b 100%)', // Dark modern gradient
        color: 'white',
        fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
      }}
    >
      {/* Container with glass effect */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '40px',
          borderRadius: '16px',
          background: 'rgba(255, 255, 255, 0.05)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
          animation: 'fadeIn 0.8s ease-out',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(20px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
          },
        }}
      >
        {/* Logo with subtle pulse */}
        <Box
          component="img"
          src={schoolLogo}
          alt="School Logo"
          sx={{
            width: 80,
            height: 80,
            marginBottom: 4,
            borderRadius: '50%',
            boxShadow: '0 0 20px rgba(255, 255, 255, 0.2)',
            animation: 'pulse 2s infinite ease-in-out',
            '@keyframes pulse': {
              '0%': { transform: 'scale(1)', boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)' },
              '70%': { transform: 'scale(1.05)', boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)' },
              '100%': { transform: 'scale(1)', boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)' },
            },
          }}
        />

        {/* Custom loading indicator */}
        <Box sx={{ position: 'relative', width: '120px', height: '6px', marginBottom: 3 }}>
          {/* Background bar */}
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: '3px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          />
          {/* Progress bar */}
          <Box
            sx={{
              position: 'absolute',
              width: `${progress}%`,
              height: '100%',
              borderRadius: '3px',
              background: 'linear-gradient(90deg, #38bdf8 0%, #818cf8 100%)',
              transition: 'width 0.2s ease-out',
            }}
          />
        </Box>

        {/* Text */}
        <Typography
          variant="body1"
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '0.5px',
            opacity: 0.9,
          }}
        >
          Preparing your experience
        </Typography>
      </Box>

      {/* Footer text */}
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          bottom: '24px',
          opacity: 0.6,
          fontSize: '12px',
          letterSpacing: '1px',
        }}
      >
        © {new Date().getFullYear()} Ree Services
      </Typography>
    </Box>
  );
};

export default LoadingSpinner;