import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef, useState } from "react";
import {
  FaApple,
  FaArrowRight,
  FaBars,
  FaChartLine,
  FaClock,
  FaEnvelope,
  FaFacebook,
  FaGooglePlay,
  FaHandshake,
  FaHeadset,
  FaInstagram,
  FaLightbulb,
  FaLinkedin,
  FaPhone,
  FaPlay,
  FaRegStar,
  FaShippingFast,
  FaStar,
  FaStore,
  FaTimes,
  FaTwitter,
  FaUsers
} from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import hero from '../Assests/hero.png';

// Features data
const features = [
  {
    icon: <FaStore size={40} />,
    title: "Store Management",
    description: "Easily manage your store's inventory, sales, and transactions from one place.",
  },
  {
    icon: <FaUsers size={40} />,
    title: "Skilled Professionals",
    description: "Find and hire skilled workers for various services, ranging from carpentry to cleaning.",
  },
  {
    icon: <FaShippingFast size={40} />,
    title: "Fast Delivery",
    description: "Enjoy timely deliveries with our fast service to get your products to your customers.",
  },
  {
    icon: <FaHandshake size={40} />,
    title: "Reliable Connections",
    description: "Build strong relationships with workers and clients, fostering trust and reliability.",
  },
  {
    icon: <FaChartLine size={40} />,
    title: "Analytics & Reports",
    description: "Track sales and performance with in-depth analytics and generate detailed reports.",
  },
  {
    icon: <FaHeadset size={40} />,
    title: "24/7 Support",
    description: "Get support anytime with our 24/7 customer service to help resolve any issues you encounter.",
  },
];

// FAQs data
const faqs = [
  {
    question: "What is Ree?",
    answer: "Ree is an app that helps you manage your store, hire skilled professionals, and streamline business operations with powerful tools for inventory, sales, and support.",
  },
  {
    question: "How can I manage my store?",
    answer: "Ree offers an intuitive store management feature that lets you track inventory, sales, profits, and expenses all in one place, with real-time updates.",
  },
  {
    question: "Can I hire workers through Ree?",
    answer: "Yes! You can hire skilled professionals like carpenters, cleaners, and more through the app. Browse through worker profiles and connect with them directly.",
  },
  {
    question: "What kind of reports can I generate?",
    answer: "Ree provides in-depth analytics and sales reports, allowing you to track performance, monitor trends, and make data-driven decisions.",
  },
  {
    question: "Is there 24/7 customer support?",
    answer: "Yes! Ree offers 24/7 customer support to assist you with any issues you may encounter with the app or your business operations.",
  },
];

// Tutorial videos data
const tutorialVideos = [
  {
    id: 1,
    title: "Getting Started with Ree",
    description: "Learn how to set up your Ree account and navigate the dashboard.",
    thumbnail: "/api/placeholder/400/225",
    duration: "3:45",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4", // Replace with actual video URLs
    isAvailable: false,
  },
  {
    id: 2,
    title: "Store Management Tutorial",
    description: "Master inventory tracking, sales processing, and reporting features.",
    thumbnail: "/api/placeholder/400/225",
    duration: "5:20",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4", // Replace with actual video URLs
    isAvailable: false,
  },
  {
    id: 3,
    title: "Hiring Professionals",
    description: "How to find, vet, and hire skilled workers through the Ree platform.",
    thumbnail: "/api/placeholder/400/225",
    duration: "4:15",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4", // Replace with actual video URLs
    isAvailable: false,
  },
  {
    id: 4,
    title: "Analytics Dashboard Walkthrough",
    description: "Get insights from your business data with Ree's powerful analytics tools.",
    thumbnail: "/api/placeholder/400/225",
    duration: "6:10",
    videoUrl: "https://cdnjs.cloudflare.com/ajax/libs/dummy-video/1.0.0/video.mp4", // Replace with actual video URLs
    isAvailable: false,
  },
];

// Testimonials data
const testimonials = [
  {
    id: 1,
    name: "Sarah Johnson",
    position: "Small Business Owner",
    comment: "Ree completely transformed how I manage my store. The inventory tracking and sales analytics have saved me countless hours every week.",
    rating: 5,
    avatar: "/api/placeholder/60/60",
  },
  {
    id: 2,
    name: "Michael Chen",
    position: "Freelance Carpenter",
    comment: "Finding consistent work used to be my biggest challenge. With Ree, I've been able to connect with new clients and grow my business steadily.",
    rating: 5,
    avatar: "/api/placeholder/60/60",
  },
  {
    id: 3,
    name: "Priya Patel",
    position: "Retail Manager",
    comment: "The reporting features alone are worth it. I can make data-driven decisions that have increased our revenue by 22% in just three months.",
    rating: 4,
    avatar: "/api/placeholder/60/60",
  },
];

// VideoModal Component
const VideoModal = ({ video, onClose }) => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    // Focus trap
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);
  
  useEffect(() => {
    // Auto-play when modal opens
    if (videoRef.current) {
      videoRef.current.play().catch(err => {
        console.log('Autoplay prevented:', err);
      });
    }
  }, []);
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
      <div className="relative bg-white rounded-lg w-full max-w-4xl overflow-hidden shadow-xl" onClick={(e) => e.stopPropagation()}>
        <div className="absolute top-4 right-4 z-10">
          <button
            onClick={onClose}
            className="bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-all"
            aria-label="Close modal"
          >
            <FaTimes size={20} />
          </button>
        </div>
        
        <div className="p-2">
          <div className="aspect-w-16 aspect-h-9 w-full overflow-hidden">
            <video
              ref={videoRef}
              className="w-full h-full object-contain"
              controls
              preload="metadata"
              src={video.videoUrl}
            >
              Your browser does not support the video tag.
            </video>
          </div>
          
          <div className="p-6">
            <h3 className="text-2xl font-bold mb-2">{video.title}</h3>
            <p className="text-gray-700">{video.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [activeVideoModal, setActiveVideoModal] = useState(null);
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);
  
  const featuresRef = useRef(null);
  const tutorialsRef = useRef(null);
  const contactRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Simulate an API request (replace with actual submission logic)
    setTimeout(() => {
      setIsLoading(false);
      setIsSuccessModalOpen(true);
    }, 2000);
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
    setActiveVideoModal(null);
  };

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const openVideoModal = (videoId) => {
    setActiveVideoModal(tutorialVideos.find(video => video.id === videoId));
  };

  const handleTestimonialChange = (index) => {
    setActiveTestimonialIndex(index);
  };

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out-cubic',
    });
    
    // Testimonial rotation
    const interval = setInterval(() => {
      setActiveTestimonialIndex((prev) => 
        prev === testimonials.length - 1 ? 0 : prev + 1
      );
    }, 6000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col justify-between">
      {/* Modern Navbar with Gradient */}
      <nav className="fixed w-full z-50 bg-white bg-opacity-95 shadow-md backdrop-blur-sm">
        <div className="flex items-center justify-between px-6 lg:px-16 py-4">
          <div className="flex items-center">
            <div className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-500 text-transparent bg-clip-text">Ree</div>
          </div>

          {/* Desktop Links */}
          <div className="hidden lg:flex space-x-8">
            <a
              href="#home"
              className="text-gray-700 hover:text-primary font-medium transition duration-300 relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-primary after:transition-all after:duration-300"
            >
              Home
            </a>
            <a
              onClick={() => scrollToSection(featuresRef)}
              className="text-gray-700 hover:text-primary font-medium transition duration-300 relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-primary after:transition-all after:duration-300 cursor-pointer"
            >
              Features
            </a>
            <a
              onClick={() => scrollToSection(tutorialsRef)}
              className="text-gray-700 hover:text-primary font-medium transition duration-300 relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-primary after:transition-all after:duration-300 cursor-pointer"
            >
              Tutorials
            </a>
            <a
              onClick={() => scrollToSection(contactRef)}
              className="text-gray-700 hover:text-primary font-medium transition duration-300 relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-primary after:transition-all after:duration-300 cursor-pointer"
            >
              Contact
            </a>
          </div>

          {/* Download Buttons */}
          <div className="hidden lg:flex items-center space-x-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.reeservices.Ree"
              className="flex items-center text-sm bg-primary text-white font-semibold px-4 py-2 rounded-full hover:bg-primary hover:shadow-lg transition duration-300"
            >
              <FaGooglePlay className="mr-2" size={16} />
              Download
            </a>
            <a
              href="https://apps.apple.com/gh/app/ree-services/id6742057296"
              className="flex items-center text-sm bg-gray-800 text-white font-semibold px-4 py-2 rounded-full hover:bg-gray-700 hover:shadow-lg transition duration-300"
            >
              <FaApple className="mr-2" size={16} />
              Download
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div
            className="lg:hidden flex items-center cursor-pointer"
            onClick={toggleMenu}
          >
            {isOpen ? (
              <FaTimes size={24} className="text-gray-700" />
            ) : (
              <FaBars size={24} className="text-gray-700" />
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden transition-all duration-300 ease-in-out overflow-hidden ${
            isOpen ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="flex flex-col items-center bg-white py-4 space-y-4">
            <a
              href="#home"
              className="w-full text-center py-2 text-gray-700 hover:text-primary font-medium"
              onClick={toggleMenu}
            >
              Home
            </a>
            <a
              onClick={() => scrollToSection(featuresRef)}
              className="w-full text-center py-2 text-gray-700 hover:text-primary font-medium cursor-pointer"
            >
              Features
            </a>
            <a
              onClick={() => scrollToSection(tutorialsRef)}
              className="w-full text-center py-2 text-gray-700 hover:text-primary font-medium cursor-pointer"
            >
              Tutorials
            </a>
            <a
              onClick={() => scrollToSection(contactRef)}
              className="w-full text-center py-2 text-gray-700 hover:text-primary font-medium cursor-pointer"
            >
              Contact
            </a>
            <div className="flex space-x-4 pt-2">
              <a
                href="#google-play-link"
                className="flex items-center text-sm bg-primary text-white font-semibold px-4 py-2 rounded-full"
              >
                <FaGooglePlay className="mr-2" size={16} />
                Download
              </a>
              <a
                href="#apple-link"
                className="flex items-center text-sm bg-gray-800 text-white font-semibold px-4 py-2 rounded-full"
              >
                <FaApple className="mr-2" size={16} />
                Download
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section with Modern Design */}
      <section className="pt-32 pb-16 lg:py-24 px-6 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100" id="home">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
          {/* Left Side - Welcome Message */}
          <div className="text-center lg:text-left space-y-6 lg:w-1/2" data-aos="fade-right">
            <div className="mb-6">
              <span className="bg-primary bg-opacity-10 text-primary px-4 py-2 rounded-full text-sm font-medium">
                Simplify Your Business
              </span>
            </div>
            <h1 className="text-4xl lg:text-6xl font-extrabold text-gray-900 leading-tight">
              Managing Stores & <span className="bg-gradient-to-r from-primary to-blue-500 text-transparent bg-clip-text">Hiring Made Easy</span>
            </h1>
            <p className="text-lg text-gray-700 max-w-lg">
              Ree helps you manage stores and hire skilled professionals effortlessly. Take control of your business with our powerful all-in-one platform.
            </p>

            {/* Action Buttons */}
            <div className="flex flex-wrap justify-center lg:justify-start gap-4 mt-8">
              <a
                href="https://play.google.com/store/apps/details?id=com.reeservices.Ree"
                className="flex items-center bg-primary text-white font-bold px-6 py-3 rounded-lg shadow-lg hover:bg-orange-600 transition duration-300 transform hover:-translate-y-1"
              >
                <FaGooglePlay className="mr-2" size={20} />
                Google Play
              </a>
              <a
                href="https://apps.apple.com/gh/app/ree-services/id6742057296"
                className="flex items-center bg-gray-800 text-white font-bold px-6 py-3 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300 transform hover:-translate-y-1"
              >
                <FaApple className="mr-2" size={20} />
                App Store
              </a>
            </div>
            
            <div className="flex flex-wrap justify-center lg:justify-start gap-4 mt-6">
              <a
                href="/worker-apply"
                className="flex items-center bg-white text-gray-800 border border-gray-300 font-bold px-6 py-3 rounded-lg shadow-md hover:bg-gray-50 transition duration-300 transform hover:-translate-y-1"
              >
                Work for Ree
                <FaArrowRight className="ml-2" size={16} />
              </a>
              <a
                href="/signup"
                className="flex items-center bg-white text-gray-800 border border-gray-300 font-bold px-6 py-3 rounded-lg shadow-md hover:bg-gray-50 transition duration-300 transform hover:-translate-y-1"
              >
                Become an Affiliate
                <FaArrowRight className="ml-2" size={16} />
              </a>
            </div>

            <p className="text-sm text-gray-500 mt-4">*Available on iPhone, iPad, and all Android devices</p>
          </div>

          {/* Right Side - Hero Image with Float Animation */}
          <div className="lg:w-1/2 flex justify-center items-center mt-12 lg:mt-0 relative" data-aos="fade-up">
            <div className="absolute w-64 h-64 bg-blue-500 rounded-full opacity-10 blur-3xl"></div>
            <div className="absolute w-48 h-48 bg-primary rounded-full opacity-10 blur-3xl -top-10 right-10"></div>
            <div className="relative z-10 animate-float shadow-2xl rounded-2xl border-8 border-white">
              <img
                src={hero}
                alt="Ree App Screenshot"
                className="w-64 h-auto object-contain"
              />
            </div>
          </div>
        </div>
        
        {/* Stats Bar */}
        <div className="container mx-auto mt-16 lg:mt-24">
          <div className="bg-white rounded-xl shadow-xl p-6 grid grid-cols-1 md:grid-cols-3 gap-6" data-aos="fade-up">
            <div className="text-center p-4">
              <h3 className="text-4xl font-bold text-primary">10,000+</h3>
              <p className="text-gray-600 mt-2">Active Users</p>
            </div>
            <div className="text-center p-4 border-t md:border-t-0 md:border-l md:border-r border-gray-200">
              <h3 className="text-4xl font-bold text-primary">2,500+</h3>
              <p className="text-gray-600 mt-2">Professionals</p>
            </div>
            <div className="text-center p-4 border-t md:border-t-0">
              <h3 className="text-4xl font-bold text-primary">98%</h3>
              <p className="text-gray-600 mt-2">Satisfaction Rate</p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section with Modern Cards */}
      <section 
        className="py-20 px-6 lg:px-16 bg-white" 
        id="features"
        ref={featuresRef}
      >
        <div className="container mx-auto text-center">
          <div className="max-w-xl mx-auto mb-16">
            <h5 className="text-primary font-semibold mb-2">POWERFUL FEATURES</h5>
            <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Everything You Need in One Place</h2>
            <p className="text-lg text-gray-700">
              Discover the powerful tools that make Ree a game-changer for your business operations and everyday tasks.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="group bg-white rounded-xl shadow-md hover:shadow-xl border border-gray-100 transition-all duration-300 p-8 overflow-hidden relative"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <div className="absolute w-24 h-24 bg-primary opacity-5 rounded-full -top-12 -right-12 group-hover:scale-150 transition-transform duration-500"></div>
                
                <div className="text-primary mb-6 group-hover:scale-110 transition-transform duration-300">
                  {feature.icon}
                </div>
                
                <h3 className="text-xl font-bold text-gray-800 mb-3">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
                
                <div className="mt-6 pt-6 border-t border-gray-100">
                  <a href="#" className="text-primary font-medium flex items-center justify-center group-hover:justify-between transition-all duration-300">
                    Learn More
                    <FaArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" size={14} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      
 {/* Tutorial Videos Section */}
 <section
        className="py-20 px-6 lg:px-16 bg-gray-50"
        id="tutorials"
        ref={tutorialsRef}
      >
        <div className="container mx-auto">
          <div className="max-w-xl mx-auto text-center mb-16">
            <h5 className="text-primary font-semibold mb-2">LEARN HOW IT WORKS</h5>
            <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Video Tutorials</h2>
            <p className="text-lg text-gray-700">
              Watch our video guides to learn how to use Ree's features effectively and get the most out of our platform.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
  {tutorialVideos.map((video) => (
    <div
      key={video.id}
      className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
      data-aos="fade-up"
      data-aos-delay={(video.id - 1) * 100}
    >
      <div 
        className={`relative group ${video.isAvailable !== false ? 'cursor-pointer' : 'cursor-default'}`} 
        onClick={() => video.isAvailable !== false && openVideoModal(video.id)}
      >
        {video.isAvailable !== false ? (
          <>
            <img
              src={video.thumbnail}
              alt={video.title}
              className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="w-16 h-16 bg-primary rounded-full flex items-center justify-center">
                <FaPlay className="text-white ml-1" size={20} />
              </div>
            </div>
            <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded">
              {video.duration}
            </div>
          </>
        ) : (
          <div className="relative w-full h-48 bg-gray-200 flex items-center justify-center">
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="w-16 h-16 bg-gray-300 rounded-full flex items-center justify-center mb-2">
                <FaClock className="text-gray-500" size={24} />
              </div>
              <span className="bg-yellow-500 text-white font-medium px-3 py-1 rounded-full text-sm">Coming Soon</span>
            </div>
            <img
              src={video.thumbnail || "/placeholder-image.jpg"}
              alt={video.title}
              className="w-full h-48 object-cover opacity-30"
            />
          </div>
        )}
      </div>
      <div className="p-6">
        <h3 className="font-bold text-lg mb-2">{video.title}</h3>
        <p className="text-gray-600 text-sm">{video.description}</p>
        {video.isAvailable === false && (
          <p className="text-yellow-600 text-xs mt-2 italic">This tutorial will be available soon</p>
        )}
      </div>
    </div>
  ))}
</div>
          
          {/* View All Tutorials Button 
          <div className="mt-12 text-center">
            <a
              href="/all-tutorials"
              className="inline-flex items-center bg-white text-primary border border-primary font-semibold px-8 py-3 rounded-lg hover:bg-primary hover:text-white transition-colors duration-300"
            >
              View All Tutorials
              <FaArrowRight className="ml-2" size={14} />
            </a>
          </div>
          */}
        </div>
      </section>
      
      {/* Video Modal */}
      {activeVideoModal && (
        <VideoModal
          video={activeVideoModal}
          onClose={closeModal}
        />
      )}

      {/* Testimonials Section - New */}
      <section className="py-20 px-6 lg:px-16 bg-white overflow-hidden">
        <div className="container mx-auto">
          <div className="max-w-xl mx-auto text-center mb-16">
            <h5 className="text-primary font-semibold mb-2">TESTIMONIALS</h5>
            <h2 className="text-4xl font-extrabold text-gray-900 mb-4">What Our Users Say</h2>
            <p className="text-lg text-gray-700">
              Hear from businesses and professionals who have transformed their operations with Ree.
            </p>
          </div>

          {/* Testimonial Cards Carousel */}
          <div className="relative max-w-4xl mx-auto">
            <div className="overflow-hidden">
              <div 
                className="flex transition-transform duration-500"
                style={{ transform: `translateX(-${activeTestimonialIndex * 100}%)` }}
              >
                {testimonials.map((testimonial, index) => (
                  <div 
                    key={testimonial.id} 
                    className="min-w-full px-4"
                  >
                    <div className="bg-white rounded-xl shadow-lg p-8 border border-gray-100">
                      <div className="flex items-center mb-4">
                        {[...Array(5)].map((_, i) => (
                          <span key={i} className="text-yellow-400 mr-1">
                            {i < testimonial.rating ? <FaStar size={18} /> : <FaRegStar size={18} />}
                          </span>
                        ))}
                      </div>
                      <blockquote className="text-lg text-gray-700 italic mb-6">"{testimonial.comment}"</blockquote>
                      <div className="flex items-center">
                        
                        <div>
                          <p className="font-bold text-gray-900">{testimonial.name}</p>
                          <p className="text-gray-600 text-sm">{testimonial.position}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Carousel Indicators */}
            <div className="flex justify-center mt-8">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleTestimonialChange(index)}
                  className={`w-3 h-3 mx-1 rounded-full transition-all duration-300 ${
                    activeTestimonialIndex === index ? "bg-primary w-8" : "bg-gray-300"
                  }`}
                  aria-label={`Testimonial ${index + 1}`}
                ></button>
              ))}
            </div>
          </div>
        </div>
      </section>
      
      {/* FAQ Section with Improved Styling */}
      <section className="py-20 px-6 lg:px-16 bg-gray-50" id="faq">
        <div className="container mx-auto max-w-4xl">
          <div className="max-w-xl mx-auto text-center mb-16">
            <h5 className="text-primary font-semibold mb-2">GOT QUESTIONS?</h5>
            <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Frequently Asked Questions</h2>
            <p className="text-lg text-gray-700">
              Find answers to common questions about the app and how it can help your business.
            </p>
          </div>
          
          {/* FAQ List */}
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <button
                  onClick={() => toggleAnswer(index)}
                  className="w-full text-left p-6 flex justify-between items-center focus:outline-none"
                >
                  <span className="font-bold text-gray-900">{faq.question}</span>
                  <div className={`w-8 h-8 flex items-center justify-center rounded-full border border-gray-200 transition-transform duration-300 ${activeIndex === index ? 'bg-primary border-primary transform rotate-45' : ''}`}>
                    <span className={`transform transition-transform duration-300 text-lg ${activeIndex === index ? 'text-white' : 'text-gray-500'}`}>
                      {activeIndex === index ? "×" : "+"}
                    </span>
                  </div>
                </button>

                {/* Answer with smooth transition */}
                <div 
                  className={`transition-all duration-300 ${
                    activeIndex === index 
                      ? 'max-h-40 opacity-100' 
                      : 'max-h-0 opacity-0'
                  } overflow-hidden`}
                >
                  <div className="p-6 pt-0 text-gray-700 border-t border-gray-100">
                    {faq.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Still Have Questions Box */}
 {/* Still Have Questions Box */}
 <div className="mt-16 bg-gradient-to-r from-primary to-orange-400 rounded-xl p-8 text-white shadow-lg" data-aos="fade-up">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-2/3 mb-6 md:mb-0">
            <div className="flex items-center mb-3">
              <FaLightbulb size={24} className="mr-3" />
              <h3 className="text-xl font-bold">Still have questions?</h3>
            </div>
            <p>Contact our team for personalized assistance with your specific needs.</p>
          </div>
          <a
            href="#contact"
            onClick={() => scrollToSection(contactRef)}
            className="bg-white text-primary font-bold px-8 py-3 rounded-lg hover:bg-gray-100 transition-colors duration-300 shadow-md"
          >
            Contact Us
          </a>
        </div>
      </div>
      
      {/* Contact Section */}
      <div ref={contactRef} id="contact" className="py-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-2">Get In Touch</h2>
          <p className="text-gray-600">We'd love to hear from you. Here's how you can reach us.</p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4">Contact Information</h3>
            
            <div className="space-y-4">
              <div className="flex items-center">
                <FaPhone className="text-primary mr-3" />
                <p>(233) 2464-3623</p>
              </div>
              
              <div className="flex items-center">
                <FaEnvelope className="text-primary mr-3" />
                <p>founder.ree@gmail.com</p>
              </div>
              
             
            </div>
            
            <div className="mt-6">
              <h4 className="font-semibold mb-3">Business Hours</h4>
              <p>Monday - Friday: 9AM - 5PM</p>
              <p>Saturday - Sunday: Closed</p>
            </div>
          </div>
          
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4">Send a Message</h3>
            
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Your Name"
                />
              </div>
              
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Your Email"
                />
              </div>
              
              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-700 mb-2">Message</label>
                <textarea
                  id="message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Your Message"
                ></textarea>
              </div>
              
              <button
                type="submit"
                className="bg-primary text-white font-bold px-6 py-3 rounded-lg hover:bg-primary-dark transition-colors duration-300 w-full"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      </div>
  </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-6 lg:px-16 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div>
              <h3 className="text-xl font-bold mb-4">Ree Services</h3>
              <p className="mb-4">Providing innovative solutions for your business needs since 2024.</p>
              <div className="flex space-x-4">
                <a href="#" className="text-white hover:text-primary transition-colors duration-300">
                  <FaFacebook size={20} />
                </a>
                <a href="#" className="text-white hover:text-primary transition-colors duration-300">
                  <FaTwitter size={20} />
                </a>
                <a href="#" className="text-white hover:text-primary transition-colors duration-300">
                  <FaInstagram size={20} />
                </a>
                <a href="#" className="text-white hover:text-primary transition-colors duration-300">
                  <FaLinkedin size={20} />
                </a>
              </div>
            </div>
            
            {/* Quick Links */}
            <div>
              <h3 className="text-xl font-bold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Home</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">About Us</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Services</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Portfolio</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Contact</a></li>
              </ul>
            </div>
            
            {/* Services */}
            <div>
              <h3 className="text-xl font-bold mb-4">Our Services</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Store Management</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Thrift Market</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">House Rental</a></li>
                <li><a href="#" className="hover:text-primary transition-colors duration-300">Post a Job</a></li>
             
              </ul>
            </div>
            
            {/* Newsletter */}
            <div>
              <h3 className="text-xl font-bold mb-4">Newsletter</h3>
              <p className="mb-4">Subscribe to our newsletter for the latest updates and offers.</p>
              <form className="flex flex-col space-y-2">
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="p-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                <button
                  type="submit"
                  className="bg-primary text-white font-bold px-4 py-3 rounded-lg hover:bg-primary-dark transition-colors duration-300"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
          
          <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p>&copy; {new Date().getFullYear()} Ree Services. All rights reserved.</p>
            <div className="mt-4 md:mt-0">
              <a href="/privacy-policy" className="text-gray-400 hover:text-primary mr-4 transition-colors duration-300">Privacy Policy</a>
              <a href="/terms-condition" className="text-gray-400 hover:text-primary mr-4 transition-colors duration-300">Terms of Service</a>
          
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage