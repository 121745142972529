import React, { useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaChevronDown,
  FaChevronUp,
  FaClipboard,
  FaHistory,
  FaMoneyBillWave,
  FaMoon,
  FaQuestion,
  FaSignOutAlt,
  FaSpinner,
  FaStore,
  FaSun,
  FaTimesCircle,
  FaWallet
} from "react-icons/fa";
import Modal from "react-modal";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import supabase from "../supabaseClient";

Modal.setAppElement("#root");

const AffiliateDashboard = () => {
  // State variables
  const [affiliateCode, setAffiliateCode] = useState("");
  const [affiliateName, setAffiliateName] = useState("");
  const [isPaymentTriggered, setIsPaymentTriggered] = useState(false);
  const [shops, setShops] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [withdrawnAmount, setWithdrawnAmount] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [earnings, setBalance] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState(100);
  const [isPaymentHistoryLoading, setIsPaymentHistoryLoading] = useState(true);
  const [completedShops, setCompletedShops] = useState(0);
  const [totalUniqueProducts, setTotalUniqueProducts] = useState(0);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // FAQ data
  const faqData = [
    {
      question: "How do I register as an agent?",
      answer: "Agents can sign up on the website by providing their full name (linked to MoMo number), phone number, and town. The system will generate a unique affiliate code for each agent.",
    },
    {
      question: "What tasks do I need to complete as an agent?",
      answer: "Agents need to create shops for sellers using the Ree App. They must scan products, enter their names and quantities. Only unique product names count (e.g., Milo - 12 still counts as 1 product). A shop must have at least 20 unique products to be considered completed.",
    },
    {
      question: "How many shops do I need to complete for payment?",
      answer: "You need to complete 10 valid shops. Each shop must have at least 20 unique products. Once completed, the app will ask for your affiliate code to trigger the payment.",
    },
    {
      question: "What happens if a shop has less than 20 unique products?",
      answer: "If a shop has less than 20 unique products, it will not count toward your total completed shops. You need to ensure each shop meets the minimum requirement.",
    },
    {
      question: "How does the payment process work?",
      answer: "After completing 10 valid shops, the app will prompt you to enter your affiliate code. If everything is valid, a payment of 100 cedis will be triggered to your MoMo account.",
    },
  ];

  // Toggle FAQ
  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  // Copy affiliate code to clipboard


  // Fetch user data
  useEffect(() => {
    let isMounted = true;
    
    // Initial fetch function
    const fetchAffiliateData = async () => {
      const { data: userSession, error: sessionError } = await supabase.auth.getSession();
      const user = userSession?.session.user;
      
      if (user) {
        const userId = user.id;
        
        // Fetch affiliate details
        const { data: affiliateData, error: affiliateError } = await supabase
          .from("affiliates")
          .select("*")
          .eq("id", userId)
          .single();
          
        if (affiliateError) {
          console.error("Error fetching affiliate data:", affiliateError);
        } else {
          const totalEarnings = affiliateData.total_earnings;
          const withdrawnAmount = affiliateData.withdrawn_amount;
          const availableBalance = totalEarnings - withdrawnAmount;
          
          // Update the available balance in Supabase
          const { error: updateError } = await supabase
            .from("affiliates")
            .update({ available_balance: availableBalance })
            .eq("id", userId);
            
          if (updateError) {
            console.error("Error updating available balance:", updateError);
          }
          
          setAffiliateName(affiliateData.full_name);
          setAffiliateCode(affiliateData.affiliate_code);
          setTotalEarnings(totalEarnings);
          setBalance(availableBalance);
          setWithdrawnAmount(withdrawnAmount);
        }
        
        // Fetch all affiliate shops
        const { data: affiliateStoreData, error: affiliateStoreError } = await supabase
          .from("affiliate_store")
          .select("id, shop_name, unique_product, shop_completed")
          .eq("affiliate_id", userId);
          
        if (affiliateStoreError) {
          console.error("Error fetching affiliate store data:", affiliateStoreError);
        } else {
          const updatedShops = affiliateStoreData.map(shop => {
            let progress = 0;
            if (shop.unique_product >= 20) {
              progress = 100;
            } else {
              progress = (shop.unique_product / 20) * 100;
            }
              
            return {
              ...shop,
              progress,
            };
          });
          
          setShops(updatedShops);
          
          const completedShops = updatedShops.filter((shop) => shop.shop_completed).length;
          const totalUniqueProducts = updatedShops.reduce(
            (total, shop) => total + shop.unique_product,
            0
          );
          
          setCompletedShops(completedShops);
          setTotalUniqueProducts(totalUniqueProducts);
        }
        
        setIsPaymentHistoryLoading(true);
        // Fetch affiliate payment history
        const { data: paymentHistoryData, error: paymentHistoryError } = await supabase
          .from("affiliate_payment_history")
          .select("*")
          .eq("affiliate_id", userId);
          
        if (paymentHistoryError) {
          console.error("Error fetching payment history:", paymentHistoryError);
          setIsPaymentHistoryLoading(false);
        } else {
          setPaymentHistory(paymentHistoryData);
          setIsPaymentHistoryLoading(false);
        }
      }
    };
    
    // Initial fetch
    fetchAffiliateData();
    
    // Set up realtime listeners for all relevant tables
    const affiliatesChannel = supabase
      .channel('affiliates_channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'affiliates' }, async (payload) => {
        if (isMounted) {
          await fetchAffiliateData();
        }
      })
      .subscribe();
      
    const affiliateStoreChannel = supabase
      .channel('affiliate_store_channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'affiliate_store' }, async (payload) => {
        if (isMounted) {
          await fetchAffiliateData();
        }
      })
      .subscribe();
      
    const paymentHistoryChannel = supabase
      .channel('payment_history_channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'affiliate_payment_history' }, async (payload) => {
        if (isMounted) {
          await fetchAffiliateData();
        }
      })
      .subscribe();
    
    // Cleanup function
    return () => {
      isMounted = false;
      affiliatesChannel.unsubscribe();
      affiliateStoreChannel.unsubscribe();
      paymentHistoryChannel.unsubscribe();
    };
  }, []);

  // Logout handler
  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  // Payment handlers
  const handlePayment = async () => {
    if (shops.filter(shop => shop.shop_completed).length >= 10 && totalEarnings >= 100) {
      setIsModalOpen(true);
    } else {
      toast.error("You need at least 10 completed shops and ₵100 earnings to cash out.");
    }
  };

  const confirmPayment = async () => {
    if (!phoneNumber) {
      toast.error("Please enter your phone number.");
      return;
    }
  
    setIsLoading(true);
    setIsPaymentTriggered(true);
  
    const { data: userSession, error: sessionError } = await supabase.auth.getSession();
    const user = userSession?.session.user;
  
    if (user) {
      const userId = user.id;
  
      // Create a new payment history record with status "pending"
      const { error: insertError } = await supabase
        .from("affiliate_payment_history")
        .insert([
          {
            affiliate_id: userId,
            amount: cashOutAmount,
            phone: phoneNumber,
            status: "Pending",
          },
        ]);
  
      if (insertError) {
        console.error("Error inserting payment history:", insertError);
        toast.error("Payment failed. Please try again.");
      } else {
        // Fetch current affiliate data to update withdrawal and available balance
        const { data: affiliateData, error: fetchError } = await supabase
          .from("affiliates")
          .select("total_earnings, withdrawn_amount")
          .eq("id", userId)
          .single();
  
        if (fetchError) {
          console.error("Error fetching affiliate data:", fetchError);
        } else {
          const totalEarnings = affiliateData.total_earnings;
          const withdrawnAmount = affiliateData.withdrawn_amount;
  
          // Calculate new withdrawn amount and available balance
          const newWithdrawnAmount = withdrawnAmount + cashOutAmount;
          const newAvailableBalance = totalEarnings - newWithdrawnAmount;
  
          // Update the affiliate's withdrawn amount and available balance in Supabase
          const { error: updateError } = await supabase
            .from("affiliates")
            .update({
              withdrawn_amount: newWithdrawnAmount,
              available_balance: newAvailableBalance,
            })
            .eq("id", userId);
  
          if (updateError) {
            console.error("Error updating affiliate balance:", updateError);
            toast.error("Failed to update your balance. Please try again.");
          } else {
            setIsModalOpen(false);
            toast.success("Payment request submitted. Awaiting approval.");
          }
        }
      }
    }
  
    setIsLoading(false);
  };

  const toggleDarkMode = () => setDarkMode(!darkMode);

  // Dynamic classes based on dark mode
  const cardClass = darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800";
  const textClass = darkMode ? "text-gray-300" : "text-gray-600";
  const tableHeaderClass = darkMode ? "bg-gray-900" : "bg-gray-100";
  const tableRowHoverClass = darkMode ? "hover:bg-gray-700" : "hover:bg-gray-50";
  const statusCompletedClass = darkMode ? "text-green-400" : "text-green-500";
  const statusPendingClass = darkMode ? "text-yellow-300" : "text-yellow-500";
  const statusFailedClass = darkMode ? "text-red-400" : "text-red-500";
  const sidebarActiveClass = darkMode ? "bg-gray-700 text-white" : "bg-orange-100 text-orange-800";
  const sidebarInactiveClass = darkMode ? "text-gray-400 hover:bg-gray-700" : "text-gray-600 hover:bg-gray-100";

  return (
    <div className={`min-h-screen flex flex-col md:flex-row ${darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"}`}>
      {/* Sidebar for larger screens */}
      <div className={`hidden md:flex md:flex-col md:w-64 p-4 shrink-0 ${darkMode ? "bg-gray-800" : "bg-white"} shadow-lg`}>
        <div className="mb-8 text-center">
          <div className="mb-1 text-xl font-bold text-orange-500">Affiliate Portal</div>
          <div className={`text-sm ${textClass}`}>Welcome, {affiliateName}</div>
        </div>
        
        <div className="space-y-1 flex-grow">
          <button
            onClick={() => setActiveTab("dashboard")}
            className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "dashboard" ? sidebarActiveClass : sidebarInactiveClass}`}
          >
            <FaWallet />
            <span>Dashboard</span>
          </button>
          
          <button
            onClick={() => setActiveTab("shops")}
            className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "shops" ? sidebarActiveClass : sidebarInactiveClass}`}
          >
            <FaStore />
            <span>Shops</span>
          </button>
          
          <button
            onClick={() => setActiveTab("payments")}
            className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "payments" ? sidebarActiveClass : sidebarInactiveClass}`}
          >
            <FaHistory />
            <span>Payment History</span>
          </button>
          
          <button
            onClick={() => setActiveTab("faq")}
            className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "faq" ? sidebarActiveClass : sidebarInactiveClass}`}
          >
            <FaQuestion />
            <span>FAQ</span>
          </button>
        </div>
        
        <div className="mt-auto pt-4 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={toggleDarkMode}
            className={`w-full flex items-center space-x-3 p-3 rounded-lg mb-2 transition-colors 
              ${darkMode ? "bg-gray-700 text-gray-200" : "bg-gray-100 text-gray-700"}`}
          >
            {darkMode ? <FaSun /> : <FaMoon />}
            <span>{darkMode ? "Light Mode" : "Dark Mode"}</span>
          </button>
          
          <button
            onClick={handleLogout}
            className="w-full flex items-center space-x-3 p-3 rounded-lg text-white bg-red-500 hover:bg-red-600 transition-colors"
          >
            <FaSignOutAlt />
            <span>Logout</span>
          </button>
        </div>
      </div>
      
      {/* Mobile header */}
      <div className={`md:hidden flex justify-between items-center p-4 ${darkMode ? "bg-gray-800" : "bg-white"} shadow-md sticky top-0 z-10`}>
        <div className="text-lg font-bold text-orange-500">Affiliate Portal</div>
        
        <div className="flex items-center space-x-2">
          <button
            onClick={toggleDarkMode}
            className="p-2 rounded-full"
          >
            {darkMode ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-gray-600" />}
          </button>
          
          <button 
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 rounded-lg"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {isMobileMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
      </div>
      
      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className={`md:hidden fixed z-20 inset-0 transform translate-x-0 transition-transform duration-300 ease-in-out ${darkMode ? "bg-gray-800" : "bg-white"}`}>
          <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
            <div className="text-lg font-bold text-orange-500">Affiliate Portal</div>
            <button 
              onClick={() => setIsMobileMenuOpen(false)}
              className="p-2 rounded-lg"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="flex flex-col p-4 space-y-4">
            <div className="text-center mb-4">
              <div className="mb-1 text-sm">Welcome,</div>
              <div className="font-bold">{affiliateName}</div>
            </div>
            
            <button
              onClick={() => {
                setActiveTab("dashboard");
                setIsMobileMenuOpen(false);
              }}
              className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "dashboard" ? sidebarActiveClass : sidebarInactiveClass}`}
            >
              <FaWallet />
              <span>Dashboard</span>
            </button>
            
            <button
              onClick={() => {
                setActiveTab("shops");
                setIsMobileMenuOpen(false);
              }}
              className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "shops" ? sidebarActiveClass : sidebarInactiveClass}`}
            >
              <FaStore />
              <span>Shops</span>
            </button>
            
            <button
              onClick={() => {
                setActiveTab("payments");
                setIsMobileMenuOpen(false);
              }}
              className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "payments" ? sidebarActiveClass : sidebarInactiveClass}`}
            >
              <FaHistory />
              <span>Payment History</span>
            </button>
            
            <button
              onClick={() => {
                setActiveTab("faq");
                setIsMobileMenuOpen(false);
              }}
              className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${activeTab === "faq" ? sidebarActiveClass : sidebarInactiveClass}`}
            >
              <FaQuestion />
              <span>FAQ</span>
            </button>
            
            <button
              onClick={handleLogout}
              className="flex items-center space-x-3 p-3 rounded-lg text-white bg-red-500 hover:bg-red-600 transition-colors mt-8"
            >
              <FaSignOutAlt />
              <span>Logout</span>
            </button>
          </div>
        </div>
      )}
      
      {/* Main content */}
      <div className="flex-grow p-4 md:p-8 overflow-auto">
        {/* Dashboard Tab */}
        {activeTab === "dashboard" && (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold">Dashboard Overview</h2>
            
            {/* Affiliate Code Card */}
            <div className={`p-4 rounded-lg shadow-md ${cardClass}`}>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">Your Affiliate Code</div>
                  <div className="text-xl font-semibold">{affiliateCode}</div>
                </div>
                <button 
            
                  className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                  title="Copy to clipboard"
                >
                  <FaClipboard className="text-gray-500" />
                </button>
              </div>
            </div>
            
            {/* Earnings Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Total Earnings */}
              <div className={`p-6 rounded-lg shadow-md ${cardClass}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="text-lg font-semibold">Total Earnings</div>
                  <div className="p-2 rounded-full bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-300">
                    <FaMoneyBillWave />
                  </div>
                </div>
                <div className="text-3xl font-bold text-green-500 dark:text-green-400">₵{totalEarnings}</div>
                <div className={`text-sm mt-1 ${textClass}`}>Total amount earned so far</div>
              </div>
              
              {/* Withdrawn Amount */}
              <div className={`p-6 rounded-lg shadow-md ${cardClass}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="text-lg font-semibold">Withdrawn</div>
                  <div className="p-2 rounded-full bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300">
                    <FaHistory />
                  </div>
                </div>
                <div className="text-3xl font-bold text-blue-500 dark:text-blue-400">₵{withdrawnAmount}</div>
                <div className={`text-sm mt-1 ${textClass}`}>Total withdrawn amount</div>
              </div>
              
              {/* Available Balance */}
              <div className={`p-6 rounded-lg shadow-md ${cardClass}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="text-lg font-semibold">Available</div>
                  <div className="p-2 rounded-full bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-300">
                    <FaWallet />
                  </div>
                </div>
                <div className="text-3xl font-bold text-purple-500 dark:text-purple-400">₵{earnings}</div>
                <div className={`text-sm mt-1 ${textClass}`}>Available for withdrawal</div>
                
                {earnings >= 100 && completedShops >= 10 && !isPaymentTriggered && (
                  <button
                    onClick={handlePayment}
                    className="w-full mt-4 bg-orange-500 text-white p-3 rounded-md hover:bg-orange-600 transition-colors flex items-center justify-center"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <FaSpinner className="animate-spin mr-2" />
                        Processing...
                      </>
                    ) : (
                      "Cash Out ₵100+"
                    )}
                  </button>
                )}
              </div>
            </div>
            
            {/* Progress Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Shops Progress */}
              <div className={`p-6 rounded-lg shadow-md ${cardClass}`}>
                <h3 className="text-lg font-semibold mb-2">Shops Completed</h3>
                <div className="flex items-center justify-between mb-2">
                  <div className="text-3xl font-bold text-blue-500 dark:text-blue-400">{completedShops} <span className="text-lg font-normal">/ 10</span></div>
                  <div className="p-2 rounded-full bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300">
                    <FaStore />
                  </div>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 h-2 rounded-full mt-2">
                  <div
                    className="h-2 bg-blue-500 rounded-full"
                    style={{ width: `${(completedShops / 10) * 100}%` }}
                  ></div>
                </div>
                <div className="text-right text-sm mt-1">{Math.round((completedShops / 10) * 100)}% completed</div>
              </div>
              
              {/* Unique Products */}
              <div className={`p-6 rounded-lg shadow-md ${cardClass}`}>
                <h3 className="text-lg font-semibold mb-2">Unique Products Added</h3>
                <div className="flex items-center justify-between mb-2">
                  <div className="text-3xl font-bold text-purple-500 dark:text-purple-400">{totalUniqueProducts}</div>
                  <div className="p-2 rounded-full bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-300">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                  </div>
                </div>
                <div className={`text-sm mt-1 ${textClass}`}>
                  Each shop needs at least 20 unique products to be considered complete
                </div>
              </div>
            </div>
            
            {/* Payment Status (if applicable) */}
            {isPaymentTriggered && (
              <div className={`p-4 rounded-lg shadow-md ${cardClass}`}>
                <h3 className="text-lg font-semibold mb-2">Payment Status</h3>
                {paymentStatus === "pending" && (
                  <div className={`flex items-center ${statusPendingClass}`}>
                    <FaSpinner className="animate-spin mr-2" /> Pending Approval
                  </div>
                )}
                {paymentStatus === "success" && (
                  <div className={`flex items-center ${statusCompletedClass}`}>
                    <FaCheckCircle className="mr-2" /> Payment Successful!
                  </div>
                )}
                {paymentStatus === "failure" && (
                  <div className="flex items-center justify-between">
                    <div className={`flex items-center ${statusFailedClass}`}>
                      <FaTimesCircle className="mr-2" /> Payment Failed!
                    </div>
                    <button
                      onClick={() => setIsModalOpen(true)}
                      className="ml-4 p-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
                    >
                      Retry
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        
        {/* Shops Tab */}
        {activeTab === "shops" && (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold">Shop Progress</h2>
            
            <div className={`rounded-lg shadow-md overflow-hidden ${cardClass}`}>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className={tableHeaderClass}>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Shop Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Unique Products</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Progress</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {shops.length > 0 ? (
                      shops.map((shop, index) => (
                        <tr key={index} className={tableRowHoverClass}>
                          <td className="px-6 py-4 whitespace-nowrap">{shop.shop_name}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{shop.unique_product}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="w-full bg-gray-200 dark:bg-gray-700 h-2 rounded-full">
                              <div
                                className={`h-2 rounded-full ${shop.progress === 100 ? 'bg-green-500' : 'bg-orange-500'}`}
                                style={{ width: `${shop.progress}%` }}
                              ></div>
                            </div>
                            <div className="text-xs mt-1 text-right">{Math.round(shop.progress)}%</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {shop.shop_completed ? (
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900 ${statusCompletedClass}`}>
                                <FaCheckCircle className="mr-1" /> Completed
                              </span>
                            ) : (
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 dark:bg-yellow-900 ${statusPendingClass}`}>
                              In Progress
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="px-6 py-4 text-center text-sm italic">
                        No shops created yet. Go to the app to create shops.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      
      {/* Payment History Tab */}
      {activeTab === "payments" && (
        <div className="space-y-6">
          <h2 className="text-2xl font-bold">Payment History</h2>
          
          <div className={`rounded-lg shadow-md overflow-hidden ${cardClass}`}>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className={tableHeaderClass}>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Amount</th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Phone</th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {isPaymentHistoryLoading ? (
                    <tr>
                      <td colSpan="4" className="px-6 py-4 text-center">
                        <FaSpinner className="inline animate-spin mr-2" /> Loading payment history...
                      </td>
                    </tr>
                  ) : paymentHistory.length > 0 ? (
                    paymentHistory.map((payment, index) => (
                      <tr key={index} className={tableRowHoverClass}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(payment.created_at).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">₵{payment.amount}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{payment.phone}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {payment.status === "Completed" ? (
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900 ${statusCompletedClass}`}>
                              <FaCheckCircle className="mr-1" /> Completed
                            </span>
                          ) : payment.status === "Pending" ? (
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 dark:bg-yellow-900 ${statusPendingClass}`}>
                              <FaSpinner className="animate-spin mr-1" /> Pending
                            </span>
                          ) : (
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 dark:bg-red-900 ${statusFailedClass}`}>
                              <FaTimesCircle className="mr-1" /> Failed
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="px-6 py-4 text-center text-sm italic">
                        No payment history available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      
      {/* FAQ Tab */}
      {activeTab === "faq" && (
        <div className="space-y-6">
          <h2 className="text-2xl font-bold">Frequently Asked Questions</h2>
          
          <div className={`rounded-lg shadow-md overflow-hidden ${cardClass}`}>
            <div className="p-6 space-y-4">
              {faqData.map((faq, index) => (
                <div key={index} className="border-b dark:border-gray-700 pb-4 last:border-b-0 last:pb-0">
                  <button
                    onClick={() => toggleFaq(index)}
                    className="flex justify-between items-center w-full py-2 text-left font-medium"
                  >
                    <span>{faq.question}</span>
                    {openFaqIndex === index ? (
                      <FaChevronUp className="ml-2 flex-shrink-0" />
                    ) : (
                      <FaChevronDown className="ml-2 flex-shrink-0" />
                    )}
                  </button>
                  {openFaqIndex === index && (
                    <div className={`mt-2 ${textClass}`}>
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
    
    {/* Payment Modal */}
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      className={`p-6 rounded-lg shadow-xl max-w-md mx-auto mt-24 ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"}`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center"
    >
      <h2 className="text-xl font-bold mb-4">Cash Out Payment</h2>
      <p className={`mb-4 ${textClass}`}>
        Enter your phone number to receive payment via mobile money:
      </p>
      
      <div className="mb-4">
        <label htmlFor="phoneNumber" className="block text-sm font-medium mb-1">
          Phone Number
        </label>
        <input
          type="text"
          id="phoneNumber"
          placeholder="e.g., 054XXXXXXX"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={`w-full p-2 border rounded-lg ${darkMode ? "bg-gray-700 border-gray-600" : "bg-white border-gray-300"}`}
        />
      </div>
      
      <div className="mb-4">
        <label htmlFor="cashOutAmount" className="block text-sm font-medium mb-1">
          Cash Out Amount (cedis)
        </label>
        <input
          type="number"
          id="cashOutAmount"
          value={cashOutAmount}
          onChange={(e) => setCashOutAmount(Number(e.target.value))}
          min="100"
          max={earnings}
          className={`w-full p-2 border rounded-lg ${darkMode ? "bg-gray-700 border-gray-600" : "bg-white border-gray-300"}`}
        />
      </div>
      
      <div className="flex justify-end space-x-3 mt-6">
        <button
          onClick={() => setIsModalOpen(false)}
          className="px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
        >
          Cancel
        </button>
        
        <button
          onClick={confirmPayment}
          className="px-4 py-2 rounded-lg bg-orange-500 text-white hover:bg-orange-600 transition-colors flex items-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <FaSpinner className="animate-spin mr-2" />
              Processing...
            </>
          ) : (
            "Confirm Cash Out"
          )}
        </button>
      </div>
    </Modal>
    
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={darkMode ? "dark" : "light"}
    />
  </div>
);
};

export default AffiliateDashboard;