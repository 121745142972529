import React from 'react';

export default function TermsScreen() {
  return (
    <div className="max-w-3xl mx-auto py-12 px-4 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-8 text-center text-indigo-600">Terms of Service</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Introduction</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              Welcome to the Ree app! By using our services, you agree to these terms and conditions. 
              Please read them carefully before using the app.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">User Responsibilities</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              As a user of the Ree app, you are responsible for your actions and the content you share. 
              You must ensure that you do not violate any laws or the rights of others.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Privacy Policy</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              Your privacy is important to us. Please review our Privacy Policy to understand 
              how we collect, use, and protect your information.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Limitation of Liability</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              Ree is not responsible for any damages, losses, or issues that arise as a result of using the app. 
              We are not liable for third-party services provided through the app.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Changes to Terms</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              Ree reserves the right to update and modify these Terms of Use at any time. 
              We will notify users of any significant changes.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Governing Law</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              These Terms of Use are governed by the laws of the jurisdiction in which Ree operates. 
              Any disputes will be resolved in accordance with the local laws.
            </p>
          </div>
        </section>
        
        <div className="mt-10 pt-6 border-t border-gray-200">
          <p className="text-center text-gray-700 font-medium">
            By continuing to use the Ree app, you acknowledge that you have read, understood, 
            and agree to these Terms of Use.
          </p>
        </div>
      </div>
    </div>
  );
}