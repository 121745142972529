import React from 'react';

export default function PrivacyScreen() {
  return (
    <div className="max-w-3xl mx-auto py-12 px-4 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-8 text-center text-indigo-600">Privacy Policy</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Introduction</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use the Ree app.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Information We Collect</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              We may collect personal information, including but not limited to your name, email address, phone number, location, and payment details. Additionally, we may collect data on how you interact with our app.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">How We Use Your Information</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              The information we collect is used to provide, maintain, and improve our services. We may also use it to communicate with you, send notifications, and process transactions.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Data Security</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              We take reasonable steps to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of data transmission over the internet or electronic storage is 100% secure.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Sharing Your Information</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              We do not sell or rent your personal information. However, we may share it with trusted third-party service providers who assist us in operating the app and providing services to you, as well as with authorities if required by law.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Cookies and Tracking</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              Our app may use cookies and similar tracking technologies to enhance your user experience. You can control the use of cookies through your device settings.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Your Rights</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              You have the right to access, update, or delete your personal information. You can also opt out of receiving promotional emails by following the unsubscribe instructions in the email.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Changes to This Policy</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any significant changes by posting the updated policy in the app.
            </p>
          </div>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-3 text-indigo-500">Contact Us</h2>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <p className="text-gray-700">
              If you have any questions or concerns about this Privacy Policy, please contact us at support@reeapp.com.
            </p>
          </div>
        </section>
        
        <div className="mt-10 pt-6 border-t border-gray-200">
          <p className="text-center text-indigo-600 font-medium">
            By continuing to use the Ree app, you acknowledge that you have read, understood, and agree to this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
}