import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import supabase from "../supabaseClient";

const SignUpAffiliate = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [town, setTown] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsLogin(!isLogin);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);
  
    // Check if all fields are filled
    if (!email || !password || !phoneNumber || !fullName || !town) {
      setErrorMessage("Please fill in all the required fields.");
      setIsLoading(false);
      return;
    }
  
    try {
      // Step 1: Check if email or phone number already exists
      const { data: existingUsers, error: checkError } = await supabase
        .from("affiliates")
        .select("id")
        .or(`email.eq.${email},phone_number.eq.${phoneNumber}`);
  
      if (checkError) {
        throw new Error("An error occurred while checking existing users.");
      }
  
      if (existingUsers.length > 0) {
        setErrorMessage(
          "An account with this email or phone number already exists. Please try logging in or use different details."
        );
        setIsLoading(false);
        return;
      }
  
      // Step 2: Sign up the user with email redirect for confirmation
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: "https://reewill.com/email-confirmed",
        },
      });
  
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }
  
      const user = data.user;
  
      if (!user) {
        setErrorMessage("Signup failed. Please try again.");
        setIsLoading(false);
        return;
      }
  
      // Step 3: Insert affiliate info into 'affiliates' table
      const { error: insertError } = await supabase.from("affiliates").insert([
        {
          id: user.id,
          full_name: fullName,
          phone_number: phoneNumber,
          town,
          email,
          affiliate_code: Math.random().toString(36).substring(2, 15),
        },
      ]);
  
      if (insertError) {
        setErrorMessage("Error saving affiliate info: " + insertError.message);
        setIsLoading(false);
        return;
      }
  
      setIsLoading(false);
      setSuccessMessage(
        "Registration successful! Please check your email to confirm your account."
      );
      setIsLogin(true);
      setEmail("");
      setPassword("");
      setFullName("");
      setPhoneNumber("");
      setTown("");
    } catch (err) {
      setErrorMessage("Error signing up: " + err.message);
      setIsLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);
  
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
  
      if (error) {
        if (error.message.includes('Email not confirmed')) {
          alert(
            'Email not verified',
            'A confirmation email has been sent to you. Kindly verify and come back to log in.'
          );
  
          const { error: resendError } = await supabase.auth.resend({
            type: 'signup',
            email,
            options: {
              emailRedirectTo: 'https://reewill.com/email-confirmed',
            },
          });
  
          if (resendError) {
            setErrorMessage("Error resending confirmation email: " + resendError.message);
          } else {
            setSuccessMessage('A new confirmation email has been sent. Please verify your email.');
          }
          setIsLoading(false);
          return;
        } else if (error.message.includes('Invalid login credentials')) {
          setErrorMessage('Incorrect email or password');
          setIsLoading(false);
          return;
        } else if (error.message.includes('email')) {
          setErrorMessage('Invalid email address');
          setIsLoading(false);
          return;
        } else {
          setErrorMessage('Login Failed: ' + error.message);
          setIsLoading(false);
          return;
        }
      }
  
      const { user } = data;
      const { data: affiliate, error: affiliateError } = await supabase
        .from("affiliates")
        .select("*")
        .eq("id", user.id)
        .single();
  
      if (affiliateError || !affiliate) {
        setErrorMessage("Access denied: You are not registered as an affiliate.");
        await supabase.auth.signOut();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSuccessMessage("Logged in successfully!");
        navigate('/dashboard');
      }
  
    } catch (err) {
      setErrorMessage("Error logging in: " + err.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage("Please enter your email to reset your password.");
      return;
    }
  
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://reewill.com/reset-password",
    });
  
    if (error) {
      console.error("Password Reset Error:", error.message);
      setErrorMessage("Failed to send password reset email. Please try again.");
    } else {
      setSuccessMessage("Password reset email sent! Check your inbox.");
      setErrorMessage("");
    }
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 to-white flex items-center justify-center p-4">
      <div className="w-full max-w-6xl overflow-hidden rounded-3xl shadow-2xl bg-white flex flex-col lg:flex-row">
        {/* Left Side - Info Section */}
        <div className="w-full lg:w-5/12 bg-gradient-to-br from-primary to-orange-500 p-8 lg:p-12 text-white relative overflow-hidden">
          {/* Background Pattern */}
          <div className="absolute -right-16 -bottom-16 w-64 h-64 bg-white opacity-10 rounded-full"></div>
          <div className="absolute -left-16 -top-16 w-64 h-64 bg-white opacity-5 rounded-full"></div>
          
          <div className="relative z-10">
            <h2 className="text-3xl lg:text-4xl font-bold mb-6">Become an Affiliate</h2>
            <p className="text-lg opacity-90 mb-8 leading-relaxed">
              Join our network and earn commissions by connecting businesses to our platform.
            </p>
            
            <div className="space-y-6">
              <div className="flex items-start space-x-3">
                <div className="bg-white bg-opacity-20 rounded-full p-1 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
                <p>Register stores for local businesses</p>
              </div>
              
              <div className="flex items-start space-x-3">
                <div className="bg-white bg-opacity-20 rounded-full p-1 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
                <p>Scan at least 20 unique products per shop</p>
              </div>
              
              <div className="flex items-start space-x-3">
                <div className="bg-white bg-opacity-20 rounded-full p-1 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
                <p>Earn 10 GHS per completed shop</p>
              </div>
              
              <div className="flex items-start space-x-3">
                <div className="bg-white bg-opacity-20 rounded-full p-1 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
                <p>Withdraw commissions after 100 GHS</p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Form Section */}
        <div className="w-full lg:w-7/12 p-8 lg:p-12">
          <div className="max-w-md mx-auto">
            <h2 className="text-3xl font-bold mb-2 text-gray-800">
              {isLogin ? "Welcome Back" : "Join Our Network"}
            </h2>
            <p className="text-gray-500 mb-8">
              {isLogin 
                ? "Log in to access your affiliate dashboard" 
                : "Create your affiliate account to start earning"
              }
            </p>

            {errorMessage && (
              <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded">
                <p>{errorMessage}</p>
              </div>
            )}

            {successMessage && (
              <div className="mb-6 p-4 bg-green-50 border-l-4 border-green-500 text-green-700 rounded">
                <p>{successMessage}</p>
              </div>
            )}

            <form onSubmit={isLogin ? handleLogin : handleSignUp} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-600 mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary focus:border-primary transition-all"
                  placeholder="yourname@example.com"
                  required
                />
              </div>

              <div>
                <div className="flex justify-between items-center mb-2">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                    Password
                  </label>
                  {isLogin && (
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className="text-sm text-primary hover:text-orange-600 focus:outline-none transition-colors"
                    >
                      Forgot Password?
                    </button>
                  )}
                </div>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary focus:border-primary transition-all"
                  placeholder="••••••••••••"
                  required
                />
              </div>

              {!isLogin && (
                <>
                  <div>
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-600 mb-2">
                      Full Name (Linked with MoMo)
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary focus:border-primary transition-all"
                      placeholder="John Doe"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-600 mb-2">
                      Phone Number (Linked with MoMo)
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary focus:border-primary transition-all"
                      placeholder="e.g., 0201234567"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="town" className="block text-sm font-medium text-gray-600 mb-2">
                      Town
                    </label>
                    <input
                      type="text"
                      id="town"
                      value={town}
                      onChange={(e) => setTown(e.target.value)}
                      className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary focus:border-primary transition-all"
                      placeholder="e.g., Accra"
                      required
                    />
                  </div>
                </>
              )}

              <button
                type="submit"
                className={`w-full bg-primary text-white font-medium py-3.5 px-4 rounded-xl transition duration-300 transform hover:translate-y-0.5 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 ${
                  isLoading ? "opacity-70 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center space-x-2">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                    <span>{isLogin ? "Logging in..." : "Creating account..."}</span>
                  </div>
                ) : (
                  isLogin ? "Log in" : "Create Account"
                )}
              </button>
            </form>

            <div className="mt-8 text-center">
              <p className="text-gray-600">
                {isLogin ? "Don't have an account?" : "Already have an account?"}
                <button
                  onClick={handleToggle}
                  className="ml-2 text-primary font-medium hover:text-orange-600 focus:outline-none transition-colors"
                >
                  {isLogin ? "Sign up" : "Log in"}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpAffiliate;