import React, { useState } from "react";
import { FiBriefcase, FiMail, FiMapPin, FiPhone, FiUpload, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import supabase from "../supabaseClient";

const WorkerApplicationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    jobTitle: "",
    passportPicture: null,
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});

  const jobOptions = [
    "Maid",
    "Cook",
    "Security Man",
    "Nanny",
    "Sales Personnel",
    "Cleaner",
    "Personal Driver",
    "Personal Teacher",
    "Senior Care Assistant",
  ];

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    // Clear error when field is updated
    if (errors[id]) {
      setErrors((prev) => ({ ...prev, [id]: null }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, passportPicture: file }));
      
      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateStep = (stepNumber) => {
    const newErrors = {};
    
    if (stepNumber === 1) {
      if (!formData.fullName.trim()) newErrors.fullName = "Name is required";
      if (!formData.email.trim()) {
        newErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "Email is invalid";
      }
      if (!formData.phone.trim()) {
        newErrors.phone = "Phone is required";
      } else if (!/^\d{10,15}$/.test(formData.phone.replace(/[^0-9]/g, ''))) {
        newErrors.phone = "Phone number is invalid";
      }
    } else if (stepNumber === 2) {
      if (!formData.address.trim()) newErrors.address = "Address is required";
      if (!formData.jobTitle) newErrors.jobTitle = "Please select a job title";
      if (!formData.passportPicture) newErrors.passportPicture = "Passport picture is required";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const nextStep = () => {
    if (validateStep(step)) {
      setStep(step + 1);
      window.scrollTo(0, 0);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateStep(2)) return;

    setIsLoading(true);
    
    try {
      // Show processing toast
      const processingToast = toast.loading("Processing your application...");
      
      // Upload the passport picture to Supabase Storage
      const fileExtension = formData.passportPicture.name.split(".").pop();
      const fileName = `${Date.now()}_${formData.fullName.replace(/\s+/g, '_')}.${fileExtension}`;
      
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("profile-pictures")
        .upload(fileName, formData.passportPicture);

      if (uploadError) {
        throw new Error(uploadError.message || "Failed to upload passport picture");
      }

      // Get the public URL of the uploaded image
      const { data: publicURLData } = supabase.storage
        .from("profile-pictures")
        .getPublicUrl(fileName);
      const photoURL = publicURLData.publicUrl;

      // Insert the worker application data into the database
      const { error: insertError } = await supabase.from("worker_applications").insert([
        {
          full_name: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          address: formData.address,
          job_role: formData.jobTitle,
          passport_picture_url: photoURL,
          status: "pending",
          application_date: new Date().toISOString(),
        },
      ]);

      if (insertError) {
        throw new Error(insertError.message || "Application submission failed");
      }

      // Update toast to success
      toast.update(processingToast, { 
        render: "Application submitted successfully!", 
        type: "success", 
        isLoading: false,
        autoClose: 3000,
      });
      
      // Reset form and navigate
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        address: "",
        jobTitle: "",
        passportPicture: null,
      });
      setPreviewUrl(null);
      
      // Navigate after a short delay to allow the success toast to be seen
      setTimeout(() => navigate("/success"), 1500);
      
    } catch (error) {
      console.error("Error submitting application:", error);
      toast.error(error.message || "Application failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Form steps
  const renderStepOne = () => (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
      <div>
        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
          Full Name
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiUser className="text-gray-400" />
          </div>
          <input
            type="text"
            id="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className={`pl-10 block w-full px-4 py-3 border ${errors.fullName ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500`}
            placeholder="John Doe"
          />
        </div>
        {errors.fullName && <p className="mt-1 text-sm text-red-600">{errors.fullName}</p>}
      </div>
      
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email Address
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiMail className="text-gray-400" />
          </div>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className={`pl-10 block w-full px-4 py-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500`}
            placeholder="john@example.com"
          />
        </div>
        {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email}</p>}
      </div>
      
      <div>
        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
          Phone Number
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiPhone className="text-gray-400" />
          </div>
          <input
            type="tel"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`pl-10 block w-full px-4 py-3 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500`}
            placeholder="+234 800 123 4567"
          />
        </div>
        {errors.phone && <p className="mt-1 text-sm text-red-600">{errors.phone}</p>}
      </div>
      
      <div className="pt-4">
        <button
          type="button"
          onClick={nextStep}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition duration-200 flex items-center justify-center"
        >
          Continue to Job Details
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );

  const renderStepTwo = () => (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-gray-800">Job Details</h2>
      
      <div>
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
          Address
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiMapPin className="text-gray-400" />
          </div>
          <textarea
            id="address"
            value={formData.address}
            onChange={handleChange}
            rows="3"
            className={`pl-10 block w-full px-4 py-3 border ${errors.address ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500`}
            placeholder="Enter your full address"
          />
        </div>
        {errors.address && <p className="mt-1 text-sm text-red-600">{errors.address}</p>}
      </div>
      
      <div>
        <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">
          Desired Position
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiBriefcase className="text-gray-400" />
          </div>
          <select
            id="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            className={`pl-10 block w-full px-4 py-3 border ${errors.jobTitle ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-white`}
          >
            <option value="">Select a position</option>
            {jobOptions.map((job, index) => (
              <option key={index} value={job}>
                {job}
              </option>
            ))}
          </select>
        </div>
        {errors.jobTitle && <p className="mt-1 text-sm text-red-600">{errors.jobTitle}</p>}
      </div>
      
      <div>
        <label htmlFor="passportPicture" className="block text-sm font-medium text-gray-700">
          Passport Picture
        </label>
        <div className="mt-2 flex flex-col items-center">
          {previewUrl ? (
            <div className="relative mb-4">
              <img
                src={previewUrl}
                alt="Preview"
                className="w-32 h-32 object-cover rounded-full border-4 border-gray-200"
              />
              <button
                type="button"
                onClick={() => {
                  setPreviewUrl(null);
                  setFormData(prev => ({ ...prev, passportPicture: null }));
                }}
                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          ) : (
            <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed ${errors.passportPicture ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full`}>
              <div className="space-y-2 text-center">
                <FiUpload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="passportPicture"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none"
                  >
                    <span>Upload a photo</span>
                    <input
                      id="passportPicture"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
              </div>
            </div>
          )}
          {errors.passportPicture && <p className="mt-1 text-sm text-red-600 self-start">{errors.passportPicture}</p>}
        </div>
      </div>
      
      <div className="flex space-x-4 pt-4">
        <button
          type="button"
          onClick={prevStep}
          className="w-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-3 px-4 rounded-lg transition duration-200"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="w-1/2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition duration-200 disabled:bg-blue-400 disabled:cursor-not-allowed flex items-center justify-center"
        >
          {isLoading ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </>
          ) : (
            "Submit Application"
          )}
        </button>
      </div>
    </div>
  );

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-xl shadow-lg">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Apply to Work for Ree</h1>
        <p className="mt-2 text-sm text-gray-600">
          Fill out the form below to apply for a position. We'll contact you as soon as possible.
        </p>
        
        {/* Progress bar */}
        <div className="mt-6">
          <div className="flex items-center justify-between mb-2">
            <span className="text-xs font-medium text-blue-600">Step {step} of 2</span>
            <span className="text-xs font-medium text-gray-500">{step === 1 ? "50%" : "100%"}</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-600 h-2 rounded-full transition-all duration-300 ease-in-out" 
              style={{ width: step === 1 ? "50%" : "100%" }}
            ></div>
          </div>
        </div>
      </div>
      
      <form onSubmit={handleSubmit}>
        {step === 1 ? renderStepOne() : renderStepTwo()}
      </form>
      
      <div className="mt-8 text-sm text-gray-500 text-center">
        By submitting this application, you agree to our{" "}
        <a href="/terms-condition" className="text-blue-600 hover:underline">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="/privacy-policy" className="text-blue-600 hover:underline">
          Privacy Policy
        </a>
      </div>
      
      <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default WorkerApplicationForm;